import React, { FC } from 'react';

interface TwitterIconProps {
  className: string;
}

const TwitterIcon: FC<TwitterIconProps> = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    className={className}
  >
    <path
      d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11Z"
      fill="#18BEF2"
    />
    <path
      d="M8.7328 15.5846C13.345 15.5846 15.8676 12.0576 15.8676 8.99905C15.8676 8.89887 15.8676 8.79914 15.8603 8.69987C16.3511 8.37222 16.7747 7.96653 17.1114 7.50179C16.6537 7.68897 16.1682 7.81172 15.6711 7.86595C16.1946 7.57669 16.5864 7.12173 16.7736 6.58574C16.2813 6.85535 15.7428 7.04535 15.1812 7.14756C14.8032 6.77648 14.3031 6.53077 13.7585 6.44843C13.2139 6.3661 12.6551 6.45174 12.1685 6.6921C11.6819 6.93245 11.2947 7.31413 11.0669 7.77806C10.839 8.242 10.7831 8.76232 10.9079 9.25852C9.9109 9.21238 8.93559 8.97325 8.04525 8.55663C7.15491 8.14002 6.36944 7.55524 5.73983 6.84025C5.41916 7.34979 5.32095 7.95298 5.46518 8.52701C5.60942 9.10104 5.98526 9.60275 6.51618 9.92998C6.11712 9.91906 5.72676 9.8197 5.37805 9.64028C5.37805 9.64975 5.37805 9.65968 5.37805 9.66961C5.37821 10.204 5.57862 10.7219 5.9453 11.1354C6.31197 11.549 6.82234 11.8327 7.38983 11.9385C7.02065 12.0314 6.63331 12.045 6.25756 11.9782C6.4178 12.4381 6.72976 12.8403 7.14982 13.1285C7.56987 13.4167 8.07702 13.5765 8.60032 13.5856C7.71236 14.2297 6.61546 14.5794 5.48609 14.5783C5.28658 14.578 5.08726 14.5668 4.88916 14.5449C6.03592 15.2242 7.37023 15.5845 8.7328 15.5828"
      fill="white"
    />
  </svg>
);

export default TwitterIcon;
