import { useEffect, useState } from 'react';

interface jwPlayer extends jwplayer.JWPlayer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setConfig(options: any): jwplayer.JWPlayer;
}

const useVideoPlayer = (
  readyToSetUp: boolean,
  element?: HTMLDivElement | null,
) => {
  const [playerInstance, setPlayerInstance] = useState<jwPlayer | null>(null);

  useEffect(() => {
    if (!playerInstance) {
      if (readyToSetUp && element) {
        const player = window.jwplayer?.(element);

        if (player) {
          setPlayerInstance(player);
        }
      }
    }
  }, [element, readyToSetUp, playerInstance]);

  return playerInstance;
};

export default useVideoPlayer;
