import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';

import NormalButton from '@/components/button/normal';
import LoadingIcon from '@/components/icons/loading-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useConsent from '@/lib/hooks/use-consent';
import useVariables from '@/lib/hooks/use-variables';

interface ConsentWrapperProps {
  requiredGroups?: string[];
}

const ConsentWrapper: FC<ConsentWrapperProps> = ({
  requiredGroups = [],
  children,
}) => {
  const variables = useVariables();
  const { isLibInitialized, currentGroups, allowAll } = useConsent();
  const [hasConsent, setHasConsent] = useState(false);

  useEffect(() => {
    if (isLibInitialized) {
      if (
        Array.isArray(currentGroups) &&
        R.equals(R.intersection(currentGroups, requiredGroups), requiredGroups)
      ) {
        setHasConsent(true);
      }
    }
  }, [isLibInitialized, currentGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  if (hasConsent) {
    return <>{children}</>;
  }

  if (!isLibInitialized) {
    return (
      <div className="w-full h-full bg-black-extra rounded-4 flex justify-center items-center p-4">
        <div className="w-full flex justify-center">
          <LoadingIcon
            id="loading-icon"
            className="w-12 md:w-24 h-12 md:h-24"
          />
        </div>
        <div className="w-full flex justify-center">
          <p className="text-small md:text-body1">
            {getVariablePlainText(variables['no-consent-embed-loading']) ||
              'Checking consent. Please wait a bit. If it takes too long, refresh the page.'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full bg-black-extra rounded-4 flex justify-center items-center p-4">
      <div className="text-center md:w-2/3">
        <h1 className="pb-2 text-body1 teair:text-white">
          {getVariablePlainText(variables['no-consent-embed-title']) ||
            'You have rejected social media cookies.'}
        </h1>
        <p className="pb-8 text-body2 teair:text-white">
          {getVariablePlainText(variables['no-consent-embed-text']) ||
            'To see this content you have to adjust your cookie settings.'}
        </p>
        <NormalButton
          size="S"
          text={
            getVariablePlainText(variables['no-consent-embed-button']) ||
            'Open cookie settings'
          }
          onClick={allowAll}
        />
      </div>
    </div>
  );
};

export default ConsentWrapper;
