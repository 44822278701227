import cls from 'classnames';
import { FC } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import { ToggleData } from '@/types/views/generic';

interface DropdownMenuItemProps {
  isActive?: boolean;
  toggleItem: ToggleData;
  onClick?: () => void;
}

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
  isActive = false,
  toggleItem,
  onClick,
}) => (
  <CustomLink
    href={toggleItem.href}
    key={toggleItem.href}
    className={cls(
      'flex items-center min-h-12 px-2 py-3 hover:bg-gray-1 text-body2',
      isActive && 'text-red-medium',
    )}
    onClick={onClick}
  >
    {toggleItem.imageSrc && (
      <div className="w-6 h-6 mr-2">
        {/* TODO: maybe create optional toggleItem.description field for alt attribute */}
        <Image src={toggleItem.imageSrc} alt={toggleItem.text} />
      </div>
    )}

    {toggleItem.text}
  </CustomLink>
);

export default DropdownMenuItem;
