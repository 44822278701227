import { RefObject, useEffect } from 'react';

const useOutsideClickNotifier = (
  refs: RefObject<HTMLElement>[],
  handler: (event?: MouseEvent) => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideEveryNode = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node),
      );

      if (isClickOutsideEveryNode) {
        handler(event);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...refs, handler]);
};

export default useOutsideClickNotifier;
