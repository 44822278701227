import cls from 'classnames';

import { ShareProps } from '.';

const shareBoxItemCommonStyles = 'p-2 hover:bg-gray-1 flex items-center';

const styles = {
  shareButton: (theme: ShareProps['theme']) =>
    cls(
      'outline-none focus:outline-none',
      theme === 'button'
        ? 'flex items-center px-3 py-2 text-white bg-black-light rounded-2 outline-none focus:outline-none hover:bg-black'
        : 'text-gray-3 hover:text-white',
    ),
  shareIcon: (theme: ShareProps['theme'], shareIconClassName?: string) =>
    cls(
      'cursor-pointer',
      theme === 'button'
        ? 'w-6 h-6 mr-2 text-white'
        : cls(
            'w-8 h-8 p-1 hover:text-white',
            shareIconClassName ??
              'text-gray-3 teair:text-white tesn:text-esn-secondary-superLight',
          ),
    ),
  shareBox: 'py-2 rounded-2 shadow-md flex flex-col text-white',
  shareBoxItem: `${shareBoxItemCommonStyles} pr-20`,
  shareBoxItemIcon: 'mr-2',
  whatsAppShare: `${shareBoxItemCommonStyles} pr-20 md:hidden`,
  copyLinkItem: `${shareBoxItemCommonStyles} cursor-pointer`,
};

export default styles;
