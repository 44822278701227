import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  className?: string;
}

const portalRoot =
  typeof window !== 'undefined' && document.getElementById('portal-root');

const Portal: FC<PortalProps> = ({ className = '', children }) => {
  const [container] = useState<HTMLDivElement>(() => {
    const element = document.createElement('div');
    element.className = className;

    return element;
  });

  useEffect(() => {
    if (portalRoot && container) {
      portalRoot.appendChild(container);
    }

    return () => {
      if (portalRoot && container) {
        portalRoot.removeChild(container);
      }
    };
  }, [container]);

  return createPortal(children, container);
};

export default Portal;
