import cls from 'classnames';
import { FC } from 'react';

import Tooltip from '@/components/tooltip';
import { TagChipType } from '@/types/views/generic';

import CustomLink from '../custom-link';
import Image from '../image';
import styles from './styles';

interface TagChipProps {
  tag: TagChipType;
  className?: string;
  isListItem?: boolean;
  onClick?: () => void;
  hasInvertedThemeColor?: boolean;
}

const TagChip: FC<TagChipProps> = ({
  tag: { name, image, link },
  className,
  isListItem = false,
  onClick,
  hasInvertedThemeColor = false,
}) => {
  const contentElement = (
    <>
      <div className="overflow-hidden flex-shrink-0 mr-2 w-6 rounded-full">
        <Image
          src={image.src}
          alt={image.description || name}
          className="aspect aspect-1-1"
        />
      </div>

      <Tooltip
        text={name}
        componentClassName="text-body2 display-block overflow-hidden whitespace-nowrap overflow-ellipsis"
        componentType="span"
      >
        {name}
      </Tooltip>
    </>
  );

  return link ? (
    <CustomLink
      className={cls(
        styles.wrapper(isListItem, hasInvertedThemeColor),
        className,
      )}
      onClick={onClick}
      {...link}
    >
      {contentElement}
    </CustomLink>
  ) : (
    <div
      className={cls(
        styles.wrapper(isListItem, hasInvertedThemeColor),
        className,
      )}
    >
      {contentElement}
    </div>
  );
};

export default TagChip;
