import React, { FC } from 'react';

interface CopyIconProps {
  className: string;
}

const CopyIcon: FC<CopyIconProps> = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.075 4.92416C16.2693 4.11858 15.1766 3.66602 14.0372 3.66602C12.8978 3.66602 11.8051 4.11858 10.9994 4.92416L9.17676 6.74662L10.3919 7.96159L12.2145 6.13913C12.6979 5.65579 13.3536 5.38425 14.0372 5.38425C14.7208 5.38425 15.3765 5.65579 15.8599 6.13913C16.3433 6.62248 16.6148 7.27804 16.6148 7.96159C16.6148 8.64515 16.3433 9.30071 15.8599 9.78405L14.0372 11.6065L15.2523 12.8215L17.075 10.999C17.8806 10.1934 18.3333 9.10085 18.3333 7.96159C18.3333 6.82234 17.8806 5.72974 17.075 4.92416ZM14.6448 8.56981L13.4297 7.35484L7.35413 13.4297L8.56924 14.6447L14.6448 8.56981ZM7.96257 18.3335C6.82318 18.3335 5.73046 17.8809 4.92479 17.0753C4.11912 16.2697 3.6665 15.1771 3.6665 14.0379C3.6665 12.8986 4.11912 11.806 4.92479 11.0004L6.74746 9.17799L7.96257 10.393L6.1399 12.2154C5.6565 12.6988 5.38493 13.3543 5.38493 14.0379C5.38493 14.7214 5.6565 15.377 6.1399 15.8603C6.6233 16.3437 7.27893 16.6152 7.96257 16.6152C8.6462 16.6152 9.30183 16.3437 9.78523 15.8603L11.6079 14.0379L12.823 15.2529L11.0003 17.0753C10.1947 17.8809 9.10195 18.3335 7.96257 18.3335Z"
      fill="white"
    />
  </svg>
);

export default CopyIcon;
