import React, { FC } from 'react';

interface WhatsappIconProps {
  className: string;
}

const WhatsappIcon: FC<WhatsappIconProps> = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    className={className}
  >
    <path
      d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11Z"
      fill="#25D366"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.85 7.08125C13.8188 6.05 12.4437 5.5 11 5.5C7.975 5.5 5.5 7.975 5.5 11C5.5 11.9625 5.775 12.925 6.25625 13.75L5.5 16.5L8.38751 15.7437C9.21251 16.1562 10.1062 16.4312 11 16.4312C14.025 16.4312 16.5 13.9563 16.5 10.9312C16.5 9.4875 15.8813 8.1125 14.85 7.08125ZM11 15.5375C10.175 15.5375 9.35 15.3313 8.6625 14.9188L8.525 14.85L6.80625 15.3312L7.2875 13.6812L7.15 13.475C6.66875 12.7188 6.4625 11.8938 6.4625 11.0688C6.4625 8.59375 8.525 6.53125 11 6.53125C12.2375 6.53125 13.3375 7.0125 14.2313 7.8375C15.125 8.73125 15.5375 9.83125 15.5375 11.0688C15.5375 13.475 13.5437 15.5375 11 15.5375ZM13.475 12.1C13.3375 12.0313 12.65 11.6875 12.5125 11.6875C12.375 11.6188 12.3062 11.6188 12.2375 11.7563C12.1687 11.8938 11.8938 12.1687 11.825 12.3062C11.7563 12.375 11.6875 12.375 11.55 12.375C11.4125 12.3063 11 12.1687 10.45 11.6875C10.0375 11.3438 9.7625 10.8625 9.69375 10.725C9.625 10.5875 9.69376 10.5187 9.76251 10.45C9.83126 10.3812 9.9 10.3125 9.96875 10.2437C10.0375 10.175 10.0375 10.1063 10.1063 10.0375C10.175 9.96875 10.1063 9.9 10.1063 9.83125C10.1063 9.7625 9.83125 9.075 9.69375 8.8C9.625 8.59375 9.48751 8.59375 9.41876 8.59375C9.35001 8.59375 9.28124 8.59375 9.14374 8.59375C9.07499 8.59375 8.93749 8.59375 8.79999 8.73125C8.66249 8.86875 8.31875 9.2125 8.31875 9.9C8.31875 10.5875 8.8 11.2063 8.86875 11.3438C8.9375 11.4125 9.83124 12.8562 11.2062 13.4062C12.375 13.8875 12.5813 13.75 12.8563 13.75C13.1313 13.75 13.6812 13.4063 13.75 13.1313C13.8875 12.7875 13.8875 12.5125 13.8188 12.5125C13.75 12.1688 13.6125 12.1688 13.475 12.1Z"
      fill="white"
    />
  </svg>
);

export default WhatsappIcon;
