const styles = {
  wrapper: 'flex flex-col',
  description: 'block mt-2 text-body2',
  title: 'text-h4',
  overline: 'text-overline text-gray-2 teair:text-white',
  tags: 'flex flex-wrap gap-x-4 gap-y-2 mt-2 md:mt-6',
  secondaryInfo: 'flex justify-between items-center',
  primaryInfo: 'flex flex-col mt-2 md:mt-4',
};

export default styles;
