import { FC } from 'react';

interface ChevronIconProps {
  className?: string;
  fill?: string;
}

export const ChevronDownIcon: FC<ChevronIconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.6574 15.6776C11.8499 15.8588 12.1501 15.8588 12.3426 15.6776L12.5702 15.4635L18.6634 9.74272C18.8535 9.56425 18.8745 9.26968 18.7117 9.06601L18.1976 8.42289C18.0154 8.19497 17.6776 8.17083 17.4649 8.37053L12.3422 13.1791C12.1498 13.3597 11.8502 13.3597 11.6578 13.1791L6.53513 8.37053C6.32239 8.17083 5.98455 8.19497 5.80237 8.42289L5.28829 9.06601C5.1255 9.26968 5.14653 9.56425 5.33661 9.74272L11.4298 15.4635L11.6574 15.6776Z"
      fill={fill}
    />
  </svg>
);

export const ChevronLeftIcon: FC<ChevronIconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.32236 11.6574C8.14124 11.8499 8.14124 12.1501 8.32236 12.3426L8.53648 12.5702L14.2573 18.6634C14.4357 18.8535 14.7303 18.8745 14.934 18.7117L15.5771 18.1976C15.805 18.0154 15.8292 17.6776 15.6295 17.4649L10.8209 12.3422C10.6403 12.1498 10.6403 11.8502 10.8209 11.6578L15.6295 6.53513C15.8292 6.32239 15.805 5.98455 15.5771 5.80237L14.934 5.28829C14.7303 5.1255 14.4357 5.14653 14.2573 5.33661L8.53648 11.4298L8.32236 11.6574Z"
      fill={fill}
    />
  </svg>
);
