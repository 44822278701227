import { createElement, FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

interface TooltipProps {
  children: JSX.Element | string;
  text: string;
  componentClassName?: string;
  componentType?: string;
  delayShow?: number;
  delayHide?: number;
}

const DEFAULT_DELAY_SHOW = 200;
const DEFAULT_DELAY_HIDE = 100;

const Tooltip: FC<TooltipProps> = ({
  children,
  text,
  componentClassName = '',
  componentType = 'div',
  delayShow = DEFAULT_DELAY_SHOW,
  delayHide = DEFAULT_DELAY_HIDE,
}) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(elementRef, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  });

  const showTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => setIsVisible(true), delayShow);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => setIsVisible(false), delayHide);
  };

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    [],
  );

  return (
    <>
      {createElement(
        componentType,
        {
          ref: setElementRef,
          className: componentClassName,
          onMouseEnter: showTooltip,
          onMouseLeave: hideTooltip,
        },
        children,
      )}

      {isVisible && typeof document !== 'undefined'
        ? createPortal(
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="px-2 py-1 bg-gray-1 rounded-0.5 text-small text-gray-3 max-w-[10rem]">
                {text}
              </div>
            </div>,
            // We need custom element for tooltip, because if we add it to the portal-root
            // then tooltip doesn't work
            document.querySelector('#tooltip') as Element,
          )
        : null}
    </>
  );
};

export default Tooltip;
