import { css } from '@emotion/css';
import cls from 'classnames';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import Portal from '@/components/portal';

interface FloatingDropdownProps {
  renderContent: (toggleFn: () => void) => JSX.Element;
  children: JSX.Element;
  triangleClassName?: string;
  referenceElement?: HTMLElement | null;
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const arrowStyle = (popperPlacement?: string) => css`
  position: absolute;
  width: 16px;
  height: 13px;
  visibility: hidden;
  z-index: 9;

  left: ${(popperPlacement === 'bottom' || popperPlacement === 'top') &&
  '10px'};
  top: ${popperPlacement === 'bottom' && '-4px'};
  bottom: ${popperPlacement === 'top' && '-4px'};

  left: ${popperPlacement === 'right' && '-4px'};
  right: ${popperPlacement === 'left' && '-4px'};

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    position: absolute;
    width: 16px;
    height: 13px;
    background: inherit;
    z-index: 9;
  }
`;

const FloatingDropdown: FC<FloatingDropdownProps> = ({
  renderContent,
  children,
  triangleClassName = '',
  referenceElement = null,
  isOpen = false,
  setIsOpen,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const arrowPopperModifier = useMemo(
    () => ({
      name: 'arrow',
      options: { element: arrowElement },
    }),
    [arrowElement],
  );

  const offsetPopperModifier = useMemo(
    () => ({
      name: 'offset',
      options: { offset: [0, 16] },
    }),
    [],
  );

  const boundedPositionPopperModifier = useMemo(
    () => ({
      ...(referenceElement && {
        name: 'preventOverflow',
        options: {
          boundary: referenceElement,
        },
      }),
    }),
    [referenceElement],
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      boundedPositionPopperModifier,
      arrowPopperModifier,
      offsetPopperModifier,
    ],
  });

  const toggleIsOpen = () => setIsOpen((prevState) => !prevState);

  const popperPlacement = attributes?.popper?.['data-popper-placement'];

  const onPortalClickOutside = useCallback(
    (ev) => {
      const contains =
        popperElement?.contains(ev.target) ||
        referenceElement?.contains(ev.target);

      if (isOpen && !contains) {
        setIsOpen(false);
      }
    },
    [referenceElement, isOpen, popperElement, setIsOpen],
  );

  useEffect(() => {
    if (typeof document !== 'undefined' && popperElement && isOpen) {
      document.addEventListener('click', onPortalClickOutside);

      return () => document.removeEventListener('click', onPortalClickOutside);
    }

    return () => undefined;
  }, [onPortalClickOutside, popperElement, isOpen]);

  return (
    <>
      {children}

      {isOpen && (
        <Portal className="relative z-20">
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div
              className={cls(arrowStyle(popperPlacement), triangleClassName)}
              ref={setArrowElement}
            />

            {renderContent(toggleIsOpen)}
          </div>
        </Portal>
      )}
    </>
  );
};

export default FloatingDropdown;
