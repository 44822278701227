import React, { FC } from 'react';

interface MuteIconProps {
  className?: string;
  fill?: string;
  text?: string;
}

const MuteIcon: FC<MuteIconProps> = ({
  className,
  fill = 'currentColor',
  text = '',
}) => {
  const renderIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8832 10.1207C21.0401 10.2804 21.0388 10.5381 20.8803 10.6962L17.6878 13.8822C17.5293 14.0404 17.2737 14.0391 17.1168 13.8793C16.9599 13.7196 16.9612 13.4619 17.1197 13.3038L20.3122 10.1178C20.4707 9.95962 20.7263 9.96092 20.8832 10.1207Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1168 10.1207C17.2737 9.96092 17.5293 9.95962 17.6878 10.1178L20.8803 13.3038C21.0388 13.4619 21.0401 13.7196 20.8832 13.8793C20.7263 14.0391 20.4707 14.0404 20.3122 13.8822L17.1197 10.6962C16.9612 10.5381 16.9599 10.2804 17.1168 10.1207Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6.00164C15 5.20938 14.1226 4.73174 13.4572 5.16178L7.96699 8.71008C7.94321 8.72545 7.92885 8.75183 7.92885 8.78014C7.92885 8.82621 7.8915 8.86356 7.84543 8.86356H5C4.44772 8.86356 4 9.31128 4 9.86356V14.1311C4 14.6833 4.44772 15.1311 5 15.1311H7.84202C7.8899 15.1311 7.92871 15.1699 7.92871 15.2177C7.92871 15.2472 7.94363 15.2746 7.96834 15.2906L13.4571 18.8379C14.1225 19.2679 14.9999 18.7903 14.9999 17.998V11.9991C14.9999 11.9991 14.9999 11.9991 14.9999 11.9991C15 11.9991 15 11.999 15 11.999V6.00164Z"
        fill={fill}
      />
    </svg>
  );

  return text ? (
    <div className="flex items-center">
      {renderIcon()}
      <span className="ml-3">{text}</span>
    </div>
  ) : (
    renderIcon()
  );
};

export default MuteIcon;
