import React, { FC } from 'react';

interface ShareIconProps {
  className?: string;
  fill?: string;
}

const ShareIcon: FC<ShareIconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.49679 14.9743C6.36896 14.9717 7.20814 14.6403 7.8471 14.0462L14.0893 17.6159C13.657 19.3067 14.5306 21.0587 16.1405 21.7296C17.7504 22.4005 19.6083 21.7868 20.5028 20.2886C21.3974 18.7905 21.0572 16.8622 19.7041 15.7612C18.351 14.6602 16.3954 14.7203 15.1124 15.9024L8.87019 12.3327C8.93564 12.0823 8.97178 11.8251 8.97789 11.5663L15.1114 8.05852C16.3289 9.16618 18.1552 9.26888 19.489 8.30469C20.8227 7.3405 21.2992 5.57307 20.6311 4.06831C19.963 2.56355 18.3329 1.73293 16.724 2.07738C15.1151 2.42184 13.9672 3.84717 13.9727 5.49379C13.9762 5.78128 14.0154 6.06722 14.0893 6.34504L8.42147 9.58538C7.51355 8.17969 5.73683 7.61619 4.18562 8.24195C2.63442 8.86771 1.74485 10.5068 2.06481 12.1497C2.38477 13.7926 3.82431 14.9774 5.49679 14.9743Z"
      fill={fill}
    />
  </svg>
);

export default ShareIcon;
