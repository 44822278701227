import * as R from 'ramda';
import { useEffect, useState } from 'react';

import {
  ONETRUST_CONSENT_CHANGED_EVENT_TYPE,
  ONETRUST_INITIALIZED_EVENT_TYPE,
} from '@/lib/constants';

const useConsent = () => {
  const [oneTrust, setOneTrust] = useState<typeof window.OneTrust | null>(null);
  const [isLibInitialized, setIsLibInitialized] = useState(false);
  const [currentGroups, setCurrentGroups] = useState<string[] | null>(null);

  const getCurrentGroupsFromLib = () => {
    if (typeof window?.OnetrustActiveGroups === 'string') {
      return window?.OnetrustActiveGroups.split(',').filter(Boolean).sort();
    }

    return null;
  };

  const allowAll = () => window?.OneTrust.AllowAll();

  const openCookieBanner = () => window?.OneTrust.ToggleInfoDisplay();

  useEffect(() => {
    const setupHook = () => {
      if (window?.OneTrust && oneTrust === null) {
        setOneTrust(window.OneTrust);
        setIsLibInitialized(true);
        setCurrentGroups(getCurrentGroupsFromLib());
      }
    };

    document.addEventListener(
      ONETRUST_INITIALIZED_EVENT_TYPE,
      () => {
        setupHook();
      },
      false,
    );

    document.addEventListener(ONETRUST_CONSENT_CHANGED_EVENT_TYPE, () => {
      const prevGroups = currentGroups?.sort() || null;
      const newGroups = getCurrentGroupsFromLib();

      if (!R.equals(prevGroups, newGroups)) setCurrentGroups(newGroups);
    });

    setupHook();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    OneTrust: oneTrust,
    isLibInitialized,
    currentGroups,
    allowAll,
    openCookieBanner,
  };
};

export default useConsent;
