export namespace OneTrust {
  export enum ConsentGroups {
    StrictlyNecessary = 'C0001',
    Performance = 'C0002',
    Functional = 'C0003',
    Targeting = 'C0004', // marketing
    Stack42 = 'STACK42',
  }

  export interface OneTrust {
    AllowAll: () => never;
    BlockGoogleAnalytics: () => never;
    Close: () => never;
    getCSS: () => never;
    GetDomainData: () => never;
    getGeolocationData: () => never;
    getHTML: () => never;
    Init: () => never;
    InitializeBanner: () => never;
    initializeCookiePolicyHtml: () => undefined;
    InsertHtml: () => never;
    InsertScript: () => never;
    IsAlertBoxClosed: () => never;
    IsAlertBoxClosedAndValid: () => never;
    LoadBanner: () => never;
    OnConsentChanged: (
      p: (event: Event & { detail: string[] }) => void,
    ) => never;
    ReconsentGroups: () => never;
    RejectAll: () => never;
    SetAlertBoxClosed: () => never;
    setGeoLocation: () => never;
    ToggleInfoDisplay: () => never;
    TriggerGoogleAnalyticsEvent: () => never;
    useGeoLocationService: () => never;
    FetchAndDownloadPC: () => never;
    changeLanguage: () => never;
    testLog: () => never;
    UpdateConsent: () => never;
    IsVendorServiceEnabled: () => never;
    UpdateGCM: () => never;
    updateConsentFromCookies: () => never;
    getPingRequest: () => never;
    getVendorConsentsRequestV2: () => never;
    showVendorsList: boolean;
  }
}
