import { css } from '@emotion/css';
import cls from 'classnames';
import { nanoid } from 'nanoid';
import Script from 'next/script';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { screens } from 'tailwind/theme';

import { registerAd } from '@/components/advertisement';
import NormalButton from '@/components/button/normal';
import MuteIcon from '@/components/icons/mute-icon';
import fetchWithCatch from '@/lib/fetch-with-catch';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useVariables from '@/lib/hooks/use-variables';
import { useStoreDispatch } from '@/store';
import { selectAds } from '@/store/slices/ads';
import {
  selectJWPlayerLibHasLoaded,
  setJWPlayerLibHasLoaded,
} from '@/store/slices/global';
import { selectAudioPlayerIsPlaying } from '@/store/slices/player';
import {
  selectCurrentPlayingVideo,
  setCurrentPlayingVideo,
} from '@/store/slices/video';
import { JWPlayerSignatureResponse } from '@/types/apis/jwplayer';
import { InternalVideo } from '@/types/views/generic';

import useVideoPlayer from './use-video-player';

// TODO: Waiting for clarification about play radio on article page when video complete
interface JWPlayerContainerProps {
  video: InternalVideo;
  autoPlay?: boolean;
  hasAd?: boolean;
  isVideoInView?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  toggleIsVideoMuted?: (isMuted?: boolean) => void;
  isUnMutedSection?: boolean;
  isGlobalRadioPlaying?: boolean;
  internalAutoPlayCheck?: boolean;
  prerollAdIndex?: number;
}

// TODO: Fix in Tailwind 3.0 < version
const videoContainerStyles = css`
  @media (max-width: ${+screens.md.slice(0, -2) - 1}px) {
    @media (orientation: landscape) {
      --tw-aspect-w: 16 !important;
      --tw-aspect-h: 9 !important;
    }
  }
`;

async function fetchSignedData(url: string) {
  const { data, error, response } =
    await fetchWithCatch<JWPlayerSignatureResponse>(() =>
      fetch(`/api/jwplayer?url=${url}`, {
        method: 'GET',
      }),
    );

  if (error || response?.status !== 200 || !data) {
    return null;
  }

  return { expires: data.expires, url: data.url };
}

const JWPlayerContainer: FC<JWPlayerContainerProps> = ({
  video,
  autoPlay = false,
  hasAd = false,
  isVideoInView = false,
  onPlay = () => null,
  onPause = () => null,
  toggleIsVideoMuted = () => null,
  isUnMutedSection = false,
  isGlobalRadioPlaying = false,
  internalAutoPlayCheck = true,
  prerollAdIndex,
}) => {
  const dispatch = useStoreDispatch();
  const updateHasLibLoadedValue = () => dispatch(setJWPlayerLibHasLoaded(true));

  const libHasLoaded = useSelector(selectJWPlayerLibHasLoaded);
  const { adChannel, initializedAdChannel } = useSelector(selectAds);
  const currentPlayingVideo = useSelector(selectCurrentPlayingVideo);

  const { currentBreakpoint, isMobile, isPortrait } = useBreakpoint();
  const variables = useVariables();

  const isAudioPlaying = useSelector(selectAudioPlayerIsPlaying);

  const playerRef = useRef<HTMLDivElement | null>(null);
  const adSlotRef = useRef<HTMLDivElement | null>(null);
  const isVideoInViewRef = useRef(isVideoInView);

  const [adRegistered, setAdRegistered] = useState(false);
  const [vastUrl, setVastUrl] = useState<string | null>(null);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [readyToSetUp, setReadyToSetup] = useState(false);
  const [isJwPlayerInitialized, setIsJwPlayerInitialized] = useState(false);
  const [signedVideoUrl, setSignedVideoUrl] = useState<string | undefined>();
  const [signedVideoExpires, setSignedVideoExpires] = useState<
    number | undefined
  >();

  const jwPlayerInstance = useVideoPlayer(readyToSetUp, playerRef.current);

  const handleOnUnmute = useCallback(() => {
    if (jwPlayerInstance) {
      onPlay();

      jwPlayerInstance.setMute(false);
      jwPlayerInstance.play();

      toggleIsVideoMuted();
    }
  }, [jwPlayerInstance, onPlay, toggleIsVideoMuted]);

  const config = useMemo(
    () => ({
      file: signedVideoUrl,
      image: video.posterUrl,
      aspectratio: isMobile ? '2:3' : '16:9',
      mute:
        (autoPlay && !isUnMutedSection) ||
        isGlobalRadioPlaying ||
        isAudioPlaying ||
        currentPlayingVideo !== video?.taggingToolId,
      ...(internalAutoPlayCheck && {
        autostart: autoPlay && 'viewable',
        autoPause: {
          viewability: true,
          pauseAds: true,
        },
      }),
      ...(hasAd && vastUrl
        ? {
            advertising: {
              client: 'googima',
              adscheduleid: nanoid(8),
              vpaidcontrols: true,
              schedule: [
                {
                  tag: vastUrl,
                  skipoffset: 5,
                },
              ],
            },
          }
        : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAd, vastUrl, video, signedVideoUrl],
  );

  useEffect(() => {
    async function initFetchSignedData() {
      const data = await fetchSignedData(video.url);

      if (!data) {
        return;
      }
      setSignedVideoUrl(data.url);
      setSignedVideoExpires(data.expires);
    }
    initFetchSignedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize jwPlayer
  useEffect(() => {
    if (jwPlayerInstance) {
      if (!isJwPlayerInitialized) {
        jwPlayerInstance.setup(config);
      }

      jwPlayerInstance.on('ready', () => {
        setIsJwPlayerInitialized(true);
      });
    }
  }, [config, isJwPlayerInitialized, jwPlayerInstance]);

  // Update aspectRatio
  useEffect(() => {
    if (jwPlayerInstance) {
      if (isMobile) {
        jwPlayerInstance.setConfig({
          aspectratio: isPortrait ? '2:3' : '16:9',
        });
      } else {
        jwPlayerInstance.setConfig({
          aspectratio: '16:9',
        });
      }
    }
  }, [isMobile, isPortrait, jwPlayerInstance]);

  // Volume
  useEffect(() => {
    if (!jwPlayerInstance) {
      return undefined;
    }

    const handleVolume = async (
      volumeParam: jwplayer.EventParams['volume'],
    ) => {
      const videoState = jwPlayerInstance.getState();
      const isVideoPlaying = videoState === 'playing';

      if (isVideoPlaying && volumeParam.volume !== 0) {
        dispatch(setCurrentPlayingVideo(video.taggingToolId));
        handleOnUnmute();
      }
    };

    jwPlayerInstance.on('volume', handleVolume);

    return () => {
      jwPlayerInstance.off('volume', handleVolume);

      return undefined;
    };
  }, [dispatch, handleOnUnmute, jwPlayerInstance, video.taggingToolId]);

  // Play
  useEffect(() => {
    if (!jwPlayerInstance) {
      return undefined;
    }

    const handlePlay = async (playParam: jwplayer.EventParams['play']) => {
      if (!isVideoInViewRef.current) {
        jwPlayerInstance.pause();

        return;
      }

      const nowInSeconds = Math.floor(Date.now() / 1000);

      if (
        !signedVideoUrl ||
        !signedVideoExpires ||
        signedVideoExpires < nowInSeconds
      ) {
        const data = await fetchSignedData(video.url);

        if (!data) {
          return;
        }
        setSignedVideoUrl(data.url);
        setSignedVideoExpires(data.expires);
      }

      dispatch(setCurrentPlayingVideo(video.taggingToolId));

      // @ts-ignore Fix types in future
      if (playParam.playReason === 'interaction') {
        jwPlayerInstance.setMute(false);

        if (isGlobalRadioPlaying || isAudioPlaying) {
          onPlay();
        }
      }
    };

    jwPlayerInstance.on('play', handlePlay);

    return () => {
      jwPlayerInstance.off('play', handlePlay);

      return undefined;
    };
  }, [
    dispatch,
    isAudioPlaying,
    isGlobalRadioPlaying,
    jwPlayerInstance,
    onPlay,
    signedVideoExpires,
    signedVideoUrl,
    video,
  ]);

  // Pause
  useEffect(() => {
    if (!jwPlayerInstance) {
      return undefined;
    }

    const handlePause = async (pauseParam: jwplayer.EventParams['pause']) => {
      // @ts-ignore Fix types in future
      if (pauseParam.pauseReason === 'interaction') {
        onPause();
      }
    };

    jwPlayerInstance.on('pause', handlePause);

    return () => {
      jwPlayerInstance.off('pause', handlePause);

      return undefined;
    };
  }, [jwPlayerInstance, onPause]);

  // Mute effect
  useEffect(() => {
    if (!jwPlayerInstance) {
      return undefined;
    }

    const handleMute = async (muteParam: jwplayer.EventParams['mute']) => {
      const videoState = jwPlayerInstance.getState();
      const isVideoPlaying = videoState === 'playing';

      if (isVideoPlaying && !muteParam.mute) {
        handleOnUnmute();
        dispatch(setCurrentPlayingVideo(video.taggingToolId));
      }
    };
    jwPlayerInstance.on('mute', handleMute);

    return () => {
      jwPlayerInstance.off('mute', handleMute);

      return undefined;
    };
  }, [
    dispatch,
    handleOnUnmute,
    isJwPlayerInitialized,
    jwPlayerInstance,
    video.taggingToolId,
  ]);

  // Is video in view effect
  useEffect(() => {
    if (jwPlayerInstance && isJwPlayerInitialized && !internalAutoPlayCheck) {
      if (isVideoInView) {
        jwPlayerInstance.play();
      } else {
        const videoState = jwPlayerInstance.getState();
        const isVideoPaused = videoState === 'paused';

        if (!isVideoPaused) {
          jwPlayerInstance.pause();
        }
      }
    }

    return () => undefined;
  }, [
    jwPlayerInstance,
    isJwPlayerInitialized,
    internalAutoPlayCheck,
    isVideoInView,
  ]);

  // Update config
  useEffect(() => {
    if (jwPlayerInstance) {
      jwPlayerInstance.setConfig({
        mute:
          (autoPlay && !isUnMutedSection) ||
          isGlobalRadioPlaying ||
          isAudioPlaying ||
          currentPlayingVideo !== video.taggingToolId,
        autostart: internalAutoPlayCheck && autoPlay && 'viewable',
      });
    }
  }, [
    autoPlay,
    currentPlayingVideo,
    internalAutoPlayCheck,
    isAudioPlaying,
    isGlobalRadioPlaying,
    isUnMutedSection,
    jwPlayerInstance,
    video.taggingToolId,
  ]);

  // Set is current video selected
  useEffect(() => {
    if (jwPlayerInstance) {
      jwPlayerInstance.setConfig({
        mute:
          currentPlayingVideo !== video.taggingToolId ||
          isAudioPlaying ||
          isGlobalRadioPlaying,
      });
    }
  }, [
    currentPlayingVideo,
    isAudioPlaying,
    isGlobalRadioPlaying,
    jwPlayerInstance,
    video.taggingToolId,
  ]);

  useEffect(() => {
    if (jwPlayerInstance && (isGlobalRadioPlaying || isAudioPlaying)) {
      const isJwPlayerVideoMuted = jwPlayerInstance.getMute();

      if (!isJwPlayerVideoMuted) {
        jwPlayerInstance.setMute(true);
        jwPlayerInstance.setConfig({
          autostart:
            internalAutoPlayCheck &&
            autoPlay &&
            'viewable' &&
            currentPlayingVideo !== video.taggingToolId,
        });
        toggleIsVideoMuted(false);
      }
    }
  }, [
    jwPlayerInstance,
    isGlobalRadioPlaying,
    toggleIsVideoMuted,
    autoPlay,
    internalAutoPlayCheck,
    currentPlayingVideo,
    video.taggingToolId,
    isAudioPlaying,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrorOccurred(true);
    }, 2000);

    setAdRegistered(false);
    setReadyToSetup(false);

    return () => {
      clearTimeout(timeout);
    };
  }, [isMobile, setAdRegistered, setReadyToSetup, setErrorOccurred]);

  // Ads ready to setup
  useEffect(() => {
    if (
      libHasLoaded &&
      !readyToSetUp &&
      ((hasAd && (vastUrl || errorOccurred)) || !hasAd)
    ) {
      setReadyToSetup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libHasLoaded, vastUrl, hasAd, errorOccurred, readyToSetUp]);

  // Register Advertisements
  useEffect(() => {
    if (
      hasAd &&
      initializedAdChannel === adChannel && // Do not register until correct init fulfilled
      adChannel &&
      !adRegistered &&
      adSlotRef.current
    ) {
      registerAd(
        adSlotRef.current,
        adChannel,
        isMobile,
        currentBreakpoint,
        (data: { vastUrl: string; provider: string }) => {
          setVastUrl(data.vastUrl);
        },
        () => setErrorOccurred(true),
        () => setErrorOccurred(true),
      );

      setAdRegistered(true);
    }
  }, [
    hasAd,
    initializedAdChannel,
    adChannel,
    adRegistered,
    adSlotRef,
    currentBreakpoint,
    isMobile,
  ]);

  useEffect(() => {
    isVideoInViewRef.current = isVideoInView;
  }, [isVideoInView]);

  return (
    <div className="relative">
      <div
        className={cls(
          'w-full aspect aspect-2-3 md:aspect-16-9',
          videoContainerStyles,
        )}
      >
        <div>
          {!libHasLoaded && (
            <Script
              id="jwplayer-script"
              src="https://cdn.jwplayer.com/libraries/MeL04Wj5.js"
              strategy="lazyOnload"
              onLoad={updateHasLibLoadedValue}
            />
          )}

          {hasAd && (
            <div
              ref={adSlotRef}
              className="hidden"
              data-slotid="ad-preroll1"
              id={`ad-preroll1${prerollAdIndex ? `-${prerollAdIndex}` : ''}`}
            />
          )}

          <div
            className={cls(
              'absolute left-0 top-0 right-0 bottom-0 bg-black transition',
              !isVideoInView ? 'bg-opacity-70 z-10' : 'bg-opacity-0 z-0',
            )}
          />

          <div>
            {!isUnMutedSection && (
              <div className="absolute z-10 top-2 left-2 md:top-4 md:left-4">
                <NormalButton
                  onClick={handleOnUnmute}
                  text={
                    <MuteIcon
                      text={
                        getVariablePlainText(
                          variables['click-to-unmute-video-text'],
                        ) || 'Click to unmute'
                      }
                    />
                  }
                  color="white"
                  className="pl-5"
                />
              </div>
            )}
          </div>

          <div className="absolute inset-0">
            <div ref={playerRef} data-withref />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JWPlayerContainer;
