import { Playout } from '@/types/apis/broadcast-manager';

import fetchWithCatch from './fetch-with-catch';

const getPlayoutsByChannelId = async (
  channelId: string,
): Promise<Playout[] | null> => {
  const { data, error } = await fetchWithCatch<Playout[]>(() =>
    fetch(`/api/channels/${channelId}/playouts`),
  );

  return !data || error ? null : data;
};

export default getPlayoutsByChannelId;
