import { useSelector } from 'react-redux';

import { selectVariablesByKey } from '@/store/slices/variables';
import { Variable } from '@/types/views/generic';

const useVariables = (): Record<string, Variable['text']> => {
  const variablesByKey = useSelector(selectVariablesByKey);

  return variablesByKey;
};

export default useVariables;
