import { Playout } from '@/types/apis/broadcast-manager';

import getPlayoutsByChannelId from './get-playouts-by-channel-id';

const getPlayoutById = async (
  playoutId: number,
  channelId: string,
): Promise<Playout | null> => {
  const playouts = await getPlayoutsByChannelId(channelId);

  return playouts?.find((p) => p.id === playoutId) ?? null;
};

export default getPlayoutById;
