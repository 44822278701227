import { format } from 'date-fns';

const getPublishDateWithCategoriesString = (
  publishDate?: string,
  categories?: string[],
) => {
  const formattedPublishDate = publishDate
    ? format(new Date(publishDate), 'dd.MM.yyyy')
    : '';

  const formattedCategories = categories?.length ? categories.join(', ') : '';

  return `${formattedPublishDate}${
    formattedPublishDate && formattedCategories ? ' / ' : ''
  }${formattedCategories}`;
};

export default getPublishDateWithCategoriesString;
