import { css } from '@emotion/css';
import cls from 'classnames';
import { FC } from 'react';

import { Placement } from '..';

interface PopoverBubbleProps {
  shadowRadius?: 'small' | 'normal';
  className?: string;
  triangleClassName?: string;
  placement: Placement;
}

const bubbleTriangleDownClass = css`
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  border-bottom: 8px solid currentColor;
`;

const smallShadow = css`
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
`;

const normalShadow = css`
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.35);
`;

const triangleSharedClasses = cls(
  'h-0 w-0 border-black-light teair:border-air-deepBlue tesn:border-esn-secondary-dark teds:border-eds-gray-light',
  bubbleTriangleDownClass,
);

const PopoverBubble: FC<PopoverBubbleProps> = ({
  shadowRadius = 'normal',
  className = '',
  children,
  placement,
  triangleClassName = '',
}) => (
  <div className={cls(className, 'relative')}>
    {['bottomLeft', 'bottomRight', 'bottomCenter'].includes(placement) && (
      <div
        className={cls(
          triangleSharedClasses,
          placement === 'bottomRight' && 'ml-3',
          placement === 'bottomLeft' && 'mr-3 ml-auto',
          placement === 'bottomCenter' &&
            'absolute left-1/2 transform -translate-x-1/2',
          triangleClassName,
        )}
      />
    )}

    <div
      className={cls(
        shadowRadius === 'small' && smallShadow,
        shadowRadius === 'normal' && normalShadow,
        'overflow-hidden rounded-2 bg-black-light teair:bg-air-deepBlue tesn:bg-esn-secondary-dark teds:bg-eds-gray-light',
      )}
    >
      {children}
    </div>

    {['topLeft'].includes(placement) && (
      <div
        className={cls(
          triangleSharedClasses,
          'transform rotate-180',
          placement === 'topLeft' && 'mr-3 ml-auto',
          triangleClassName,
        )}
      />
    )}
  </div>
);

export default PopoverBubble;
