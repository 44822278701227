import cls from 'classnames';
import React, { FC, useRef, useState } from 'react';

import useOutsideClickNotifier from '@/lib/hooks/use-outside-click-notifier';

import DropdownMenuItem from './dropdown-menu-item';
import PopoverBubble from './popover-bubble';

export type Placement =
  | 'bottomLeft'
  | 'bottomRight'
  | 'bottomCenter'
  | 'topLeft'
  | 'topRight';

interface PopoverProps {
  renderContent: (toggleFn: () => void) => JSX.Element;
  className?: string;
  contentClassName?: string;
  children: JSX.Element;
  triangleClassName?: string;
  placement?: Placement;
}

const Popover: FC<PopoverProps> = ({
  renderContent,
  className = '',
  contentClassName = '',
  children,
  triangleClassName = '',
  placement = 'bottomRight',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const popoverBubbleRef = useRef<HTMLDivElement | null>(null);

  const toggleIsOpen = () => setIsOpen((prevState) => !prevState);

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useOutsideClickNotifier([popoverBubbleRef], handleClickOutside);

  return (
    <div className={cls('relative', className)}>
      <div className="relative inline-block">
        {React.cloneElement(children, {
          onClick: async () => {
            // TODO: refactor this code, Share component should still work
            const isNativeShare = await children.props.onClick?.();

            if (!isNativeShare) {
              toggleIsOpen();
            }
          },
          ref: popoverBubbleRef,
        })}

        {isOpen && (
          <div
            className={cls(
              'absolute z-20',
              placement === 'bottomLeft' && 'right-0',
              placement === 'topLeft' && 'right-0 bottom-full',
              placement === 'bottomCenter' &&
                'left-1/2 transform -translate-x-1/2',
              contentClassName,
            )}
          >
            <PopoverBubble
              shadowRadius="small"
              className="mt-2"
              triangleClassName={triangleClassName}
              placement={placement}
            >
              {renderContent(toggleIsOpen)}
            </PopoverBubble>
          </div>
        )}
      </div>
    </div>
  );
};

export { DropdownMenuItem, PopoverBubble };

export default Popover;
