import cls from 'classnames';
import { FC } from 'react';

import Video from '@/components/video';
import useAudioPlayer from '@/lib/hooks/use-audio-player';
import usePlayer from '@/lib/hooks/use-player';
import { VideoEntry } from '@/types/views/sections';

import styles from './styles';

export type VideoContainerProps = Pick<VideoEntry, 'video'> & {
  isInline?: boolean;
  shouldShowVideoInfo?: boolean;
  containerClassName?: string;
  autoPlay?: boolean;
  hasAd?: boolean;
  isVideoInView?: boolean;
  videoContainerRef?: (node: HTMLDivElement) => void;
  toggleIsVideoMuted?: (isMuted?: boolean) => void;
  isUnMutedSection?: boolean;
  isGlobalRadioPlaying?: boolean;
  internalAutoPlayCheck?: boolean;
  prerollAdIndex?: number;
};

const VideoContainer: FC<VideoContainerProps> = ({
  video,
  isInline = false,
  shouldShowVideoInfo = true,
  containerClassName = '',
  autoPlay = false,
  hasAd = false,
  isVideoInView = true,
  videoContainerRef = null,
  toggleIsVideoMuted = () => null,
  isUnMutedSection = false,
  isGlobalRadioPlaying = false,
  internalAutoPlayCheck = true,
  prerollAdIndex,
}) => {
  const { onPause: pauseAudioPlayer } = useAudioPlayer();
  const radioPlayer = usePlayer();

  return (
    <div className={cls(styles.wrapper(isInline), containerClassName)}>
      <Video
        ref={videoContainerRef}
        video={video}
        shouldShowVideoInfo={shouldShowVideoInfo}
        onPlay={() => {
          pauseAudioPlayer();
          radioPlayer?.stop();
        }}
        autoPlay={autoPlay}
        hasAd={hasAd}
        isVideoInView={isVideoInView}
        toggleIsVideoMuted={toggleIsVideoMuted}
        isUnMutedSection={isUnMutedSection}
        isGlobalRadioPlaying={isGlobalRadioPlaying}
        internalAutoPlayCheck={internalAutoPlayCheck}
        prerollAdIndex={prerollAdIndex}
      />
    </div>
  );
};

export default VideoContainer;
