import cls from 'classnames';
import React, { FC, memo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useBreakpoint from '@/lib/hooks/use-breakpoint';

import CustomLink from '../custom-link';
import CopyIcon from '../icons/copy-icon';
import FacebookIcon from '../icons/facebook-icon';
import ShareIcon from '../icons/share-icon';
import TwitterIcon from '../icons/twitter-icon';
import WhatsAppIcon from '../icons/whatsapp-icon';
import Popover, { Placement } from '../popover';
import styles from './styles';

export type ShareProps = {
  urlToShare: string;
  shareOnFacebook?: boolean;
  shareOnTwitter?: boolean;
  shareOnWhatsApp?: boolean;
  copyUrl?: boolean;
  renderButton?: (togglePopover: () => unknown) => JSX.Element;
  shareBoxPlacement?: Placement;
  shareBoxItemClassName?: string;
  utmInfix?: string;
  shareIconClassName?: string;
  forceShareBoxHiding?: boolean;
} & (
  | {
      theme: 'button';
      buttonText: string;
    }
  | {
      theme?: 'icon';
      buttonText?: never;
    }
);

enum ShareType {
  Facebook = 'facebook',
  Twitter = 'twitter',
  WhatsApp = 'whatsapp',
  Url = 'url',
}

const addUtmTagsToShareUrl = (
  url: string,
  shareType: ShareType,
  utmInfix: string,
  shouldEncode = true,
) => {
  const urlObject = new URL(url);
  urlObject.searchParams.append('utm_source', `web-${utmInfix}${shareType}`);
  urlObject.searchParams.append('utm_campaign', `web-${utmInfix}share`);

  return shouldEncode ? encodeURIComponent(urlObject.href) : urlObject.href;
};

const facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
const twitterShareUrl = 'https://twitter.com/share?url=';
const whatsAppShareUrl = 'https://wa.me/?text=';

const Share: FC<ShareProps> = memo(
  ({
    theme = 'icon',
    buttonText = '',
    urlToShare,
    shareOnFacebook = true,
    shareOnTwitter = true,
    shareOnWhatsApp = true,
    copyUrl = true,
    renderButton,
    shareBoxPlacement = 'bottomLeft',
    shareBoxItemClassName,
    utmInfix = '',
    shareIconClassName,
    forceShareBoxHiding = false,
  }) => {
    const { isMobile } = useBreakpoint();

    const handleShareIconClick = async () => {
      let isNativeShare = false;

      if (isMobile && navigator.share) {
        try {
          await navigator.share({
            url: addUtmTagsToShareUrl(
              urlToShare,
              ShareType.Url,
              utmInfix,
              false,
            ),
          });
        } catch (err) {
          console.error(err);
        }

        isNativeShare = true;
      }

      return isNativeShare;
    };

    if (!shareOnFacebook && !shareOnTwitter && !shareOnWhatsApp && !copyUrl) {
      return null;
    }

    const renderContent = (togglePopover: () => void) => (
      <div className={styles.shareBox}>
        {shareOnFacebook && (
          <CustomLink
            isExternal
            href={`${facebookShareUrl}${addUtmTagsToShareUrl(
              urlToShare,
              ShareType.Facebook,
              utmInfix,
            )}`}
            className={cls(styles.shareBoxItem, shareBoxItemClassName)}
            onClick={togglePopover}
          >
            <FacebookIcon className={styles.shareBoxItemIcon} />
            Facebook
          </CustomLink>
        )}

        {shareOnTwitter && (
          <CustomLink
            isExternal
            href={`${twitterShareUrl}${addUtmTagsToShareUrl(
              urlToShare,
              ShareType.Twitter,
              utmInfix,
            )}`}
            className={cls(styles.shareBoxItem, shareBoxItemClassName)}
            onClick={togglePopover}
          >
            <TwitterIcon className={styles.shareBoxItemIcon} />
            Twitter
          </CustomLink>
        )}

        {shareOnWhatsApp && (
          <CustomLink
            isExternal
            href={`${whatsAppShareUrl}${addUtmTagsToShareUrl(
              urlToShare,
              ShareType.WhatsApp,
              utmInfix,
            )}`}
            className={cls(styles.whatsAppShare, shareBoxItemClassName)}
            onClick={togglePopover}
          >
            <WhatsAppIcon className={styles.shareBoxItemIcon} />
            Whatsapp
          </CustomLink>
        )}

        {copyUrl && (
          <CopyToClipboard
            text={addUtmTagsToShareUrl(
              urlToShare,
              ShareType.Url,
              utmInfix,
              false,
            )}
          >
            <div
              className={cls(styles.copyLinkItem, shareBoxItemClassName)}
              onClick={togglePopover}
            >
              <CopyIcon className={styles.shareBoxItemIcon} />
              Link kopieren
            </div>
          </CopyToClipboard>
        )}
      </div>
    );

    const renderPopoverButton = () =>
      renderButton ? (
        renderButton(handleShareIconClick)
      ) : (
        <button
          className={styles.shareButton(theme)}
          onClick={handleShareIconClick}
        >
          <ShareIcon className={styles.shareIcon(theme, shareIconClassName)} />
          {theme === 'button' && <span>{buttonText}</span>}
        </button>
      );

    return (
      <Popover
        renderContent={renderContent}
        className="leading-[0]"
        triangleClassName="text-black-light"
        placement={shareBoxPlacement}
        contentClassName={forceShareBoxHiding ? 'hidden' : ''}
      >
        {renderPopoverButton()}
      </Popover>
    );
  },
);

export default Share;
