import { PlayerState } from '@/store/slices/player';

const getPlayerMetadataFromIcyMetadata = (
  icyMetadata: MetadataIcy,
): PlayerState['metadata'] => {
  try {
    const [artist, title] = icyMetadata.StreamTitle.split(' ˗ ');

    return {
      artist,
      artists: null,
      title,
      coverUrl: icyMetadata.StreamUrl,
    };
  } catch (error) {
    return null;
  }
};

export default getPlayerMetadataFromIcyMetadata;
