import cls from 'classnames';

const styles = {
  wrapper: (isListItem: boolean, hasInvertedThemeColor: boolean) =>
    cls(
      'inline-flex items-center max-w-full bg-black-light hover:bg-gray-1 hover:text-white tlight:hover:bg-red-light-darker teair:text-white',
      !hasInvertedThemeColor &&
        'teair:bg-air-deepBlue teair:text-white teair:hover:bg-air-mediumBlue',
      !isListItem ? 'rounded-8 p-1 pr-4' : 'px-2 py-3',
    ),
};

export default styles;
