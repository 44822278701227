import cls from 'classnames';
import { FC } from 'react';

import MultipleTagsChips from '@/components/multiple-tags-chips';
import Share from '@/components/share';
import TagChip from '@/components/tag-chip';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import getPublishDateWithCategoriesString from '@/lib/get-publish-date-with-categories-string';
import usePageTheme from '@/lib/hooks/use-page-theme';
import { mapToSlug } from '@/lib/utils';
import { InternalVideo } from '@/types/views/generic';

import styles from './styles';

interface VideoInfoProps {
  video: InternalVideo;
  className?: string;
}

const VideoInfo: FC<VideoInfoProps> = ({ video, className }) => {
  const {
    title,
    description,
    tags,
    contentCategories,
    publishDate,
    taggingToolId,
  } = video;

  const { isEventTheme } = usePageTheme();

  const overline = getPublishDateWithCategoriesString(
    publishDate,
    contentCategories,
  );

  const renderShare = () => (
    <Share
      urlToShare={`${getBaseUrlIfBrowser()}/watch/videos/${taggingToolId}-${mapToSlug(
        title,
      )}`}
      shareBoxPlacement="bottomLeft"
    />
  );

  return (
    <div className={cls(className, styles.wrapper)}>
      <div className={styles.secondaryInfo}>
        <div className={styles.overline}>{overline}</div>

        {renderShare()}

        {/* TODO: update after R1 when like functionality is introduced */}
        {/* <div className="flex">
          <div className="hidden md:block">
            {renderShare()}
          </div>

          <button>
            <MenuDotsIcon className="block md:hidden" />
          </button>
        </div> */}
      </div>

      <div className={styles.primaryInfo}>
        <div className={styles.title}>{title}</div>

        {description && <div className={styles.description}>{description}</div>}

        {!isEventTheme && !!tags?.length && (
          <div className="mt-2 md:mt-6">
            <ul className="hidden md:flex flex-wrap gap-x-4 gap-y-2">
              {tags.map((tag) => (
                <li key={tag.name} className="min-w-0">
                  <TagChip tag={tag} />
                </li>
              ))}
            </ul>

            <MultipleTagsChips tags={tags} className="md:hidden" />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoInfo;
