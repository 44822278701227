import React, { FC } from 'react';

interface FacebookIconProps {
  className: string;
}

const FacebookIcon: FC<FacebookIconProps> = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    className={className}
  >
    <path
      d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11Z"
      fill="#1877F2"
    />
    <path
      d="M16.5 11C16.5 7.975 14.025 5.5 11 5.5C7.975 5.5 5.5 7.975 5.5 11C5.5 13.75 7.49375 16.0188 10.1062 16.4312V12.5812H8.73125V11H10.1062V9.7625C10.1062 8.3875 10.9313 7.63125 12.1688 7.63125C12.7875 7.63125 13.4062 7.76875 13.4062 7.76875V9.14375H12.7188C12.0312 9.14375 11.825 9.55625 11.825 9.96875V11H13.3375L13.0625 12.5812H11.7563V16.5C14.5063 16.0875 16.5 13.75 16.5 11Z"
      fill="white"
    />
  </svg>
);

export default FacebookIcon;
