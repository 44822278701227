import cls from 'classnames';
import { FC, memo, useState } from 'react';

import FloatingDropdown from '@/components/floating-dropdown';
import { ChevronDownIcon } from '@/components/icons/chevron-icon';
import TagChip from '@/components/tag-chip';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { TagChipType } from '@/types/views/generic';

interface MultipleTagsChipsProps {
  tags: TagChipType[];
  className?: string;
  hasInvertedThemeColor?: boolean;
}

const MultipleTagsChips: FC<MultipleTagsChipsProps> = memo(
  ({ tags, className = '', hasInvertedThemeColor }) => {
    const variables = useVariables();
    const [referenceElement, setReferenceElement] =
      useState<HTMLElement | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const multipleCreatorsText =
      getVariablePlainText(variables['multiple-tags-chip-text']) ||
      'Multiple Creators';

    const renderContent = () => (
      <div className="flex flex-col bg-black-light rounded-2 relative z-10 w-60 overflow-hidden hover:bg-gray-1 hover:text-white tlight:hover:bg-red-light-darker">
        {tags.map((tag) => (
          <TagChip
            tag={tag}
            key={tag.name}
            isListItem
            hasInvertedThemeColor={hasInvertedThemeColor}
          />
        ))}
      </div>
    );

    return (
      <div className={cls(className, 'max-w-full min-w-0')}>
        {tags.length > 1 ? (
          <FloatingDropdown
            renderContent={renderContent}
            triangleClassName="bg-black-light"
            referenceElement={referenceElement}
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
          >
            <div
              className={cls(
                'inline-flex items-center p-1 pl-2 pr-4 max-w-full rounded-8 bg-black-light cursor-pointer hover:bg-gray-1 text-red-light tlight:hover:bg-red-light-darker',
                !hasInvertedThemeColor &&
                  'teair:bg-air-deepBlue teair:text-white teair:hover:bg-air-mediumBlue',
              )}
              ref={setReferenceElement}
              onClick={() => setIsDropdownOpen((prevState) => !prevState)}
            >
              <ChevronDownIcon
                className={cls(isDropdownOpen && 'transform rotate-180')}
              />

              <span className="ml-1 text-body2">{multipleCreatorsText}</span>
            </div>
          </FloatingDropdown>
        ) : (
          <TagChip
            tag={tags[0]}
            key={tags[0].name}
            hasInvertedThemeColor={hasInvertedThemeColor}
          />
        )}
      </div>
    );
  },
);

export default MultipleTagsChips;
